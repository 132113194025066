import { useEffect } from "react"

import { clearStorage, saveCurrentPosition } from "@garantidos/utils"
import { trackPageview } from "@garantidos/utils/tracking"

const useHome = () => {
  const trackingInfo = {
    pageview: {
      segment: "pageview"
    }
  }

  useEffect(() => {
    const ignoreItens = ["appGeoloc", "cookie-bar-styles"]
    clearStorage(ignoreItens)
    trackPageview(trackingInfo.pageview)
    saveCurrentPosition()
  }, [])

  return {
    trackingInfo
  }
}

export default useHome
